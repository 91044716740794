var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"input",class:{
    'input--unit': _vm.unit !== '',
    'input--horizontal': _vm.direction === 'horizontal',
    'w-full': !_vm.width,
  },attrs:{"required":_vm.required}},[(_vm.label || _vm.required)?_c('div',{staticClass:"items-center mb-4 row"},[(_vm.label)?_c('Title',{staticClass:"text-blue-lighter",attrs:{"title":_vm.label,"size":"small"}}):_vm._e(),(_vm.required)?_c('span',{staticClass:"ml-auto text-sm text-signal-red"},[_vm._v(" Required ")]):_vm._e(),(_vm.invalidText)?_c('span',{staticClass:"ml-auto text-sm text-signal-red"},[_vm._v(" "+_vm._s(_vm.invalidText)+" ")]):_vm._e()],1):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.inputValue),expression:"inputValue",modifiers:{"lazy":true}}],staticClass:"input__field",class:{ 'border--bottom': _vm.border || !_vm.validInput },style:({
      width: _vm.width + 'px',
      textAlign: _vm.align,
      height: _vm.height,
      resize: _vm.resize,
    }),attrs:{"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max},domProps:{"value":(_vm.inputValue)},on:{"change":function($event){_vm.inputValue=$event.target.value}}}),(_vm.unit)?_c('span',{staticClass:"input__unit"},[_vm._v(_vm._s(_vm.unit))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }