<template>
  <label
    class="input"
    :class="{
      'input--unit': unit !== '',
      'input--horizontal': direction === 'horizontal',
      'w-full': !width,
    }"
    :required="required"
  >
    <div v-if="label || required" class="items-center mb-4 row">
      <Title v-if="label" :title="label" size="small" class="text-blue-lighter" />

      <span v-if="required" class="ml-auto text-sm text-signal-red"> Required </span>

      <span v-if="invalidText" class="ml-auto text-sm text-signal-red">
        {{ invalidText }}
      </span>
    </div>

    <textarea
      v-model.lazy="inputValue"
      class="input__field"
      :class="{ 'border--bottom': border || !validInput }"
      :style="{
        width: width + 'px',
        textAlign: align,
        height: height,
        resize: resize,
      }"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    />

    <span v-if="unit" class="input__unit">{{ unit }}</span>
  </label>
</template>

<script>
import { Title } from "cavea-design-system";

export default {
  name: "InputTextarea",

  components: {
    Title,
  },

  props: {
    label: { type: String, default: "" },
    required: { type: Boolean },
    width: { type: [String, Number], default: null },
    border: { type: Boolean, default: false },
    align: { type: String, default: null },
    placeholder: { type: String, default: "" },
    value: { type: [String, Number], default: null },
    index: { type: Number, default: null },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    direction: { type: String, default: "vertical" },
    unit: { type: String, default: "" },
    invalidText: { type: String, default: null },
    height: { type: String, default: null },
    resize: { type: String, default: "both" },
  },

  data() {
    return {
      inputValue: "",
    };
  },

  computed: {
    validInput() {
      if (this.required && !this.inputValue) {
        return false;
      }
      return true;
    },
  },

  watch: {
    value() {
      this.inputValue = this.value;
    },

    /**
     * @emits {string|number} value
     */
    inputValue() {
      // Set min/max values if number
      if (this.type === "number" && this.min && this.inputValue < this.min) {
        this.inputValue = this.min;
      }
      if (this.type === "number" && this.max && this.inputValue > this.max) {
        this.inputValue = this.max;
      }

      if (this.index !== null) {
        this.$emit("onChange", this.inputValue, this.index);
      } else {
        this.$emit("onChange", this.inputValue);
      }
    },
  },

  mounted() {
    if (this.value) {
      this.inputValue = this.value;
    }
  },
};
</script>
<style lang="scss">
@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/globals";

.input {
  display: flex;
  flex-direction: column;

  &--horizontal {
    flex-direction: row;
    align-items: center;

    .input__label {
      font-size: 0.875rem;
      margin-right: 1rem;
    }

    > .row {
      margin-bottom: 0 !important;
    }
  }

  &--unit {
    .input__field {
      padding-right: 2rem;
    }
  }
}

.input__field {
  z-index: 1000;
  outline: 0;
  font-size: 0.875rem;
  color: color(black, base);
  font-weight: 400;
  padding: 0.5rem;
  height: 2.5rem;
  background: transparent;
  border-radius: $border-radius;
  transition: var(--transition-out);
  background-color: color(white, off);
  font-family: $primary-font-stack;
  border: 1px solid color(border, base);

  &::placeholder {
    color: color(blue, lighter);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus,
  &:hover {
    border: 1px solid color(cta, primary);

    + .input__icon {
      color: color(blue, light);
    }
  }
}

.input__label {
  line-height: 1;
  font-weight: 500;
  color: color(blue, light);
}

.input__unit {
  color: #aaa;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  z-index: 1000;
  transform: translateY(-50%);
}
</style>
